import { Box, Stack } from '@mui/material';

import { Title } from '../../elements/Title';
import { useParams } from 'react-router-dom';
import MorphPhotosListTableComponent from './components/MorphPhotosListTableComponent';

export function PageHeader() {
    const { operatorId } = useParams();

    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Фотографии</Title>
        </Stack>
    );
}

export default function MorphPhotosListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader/>
            <MorphPhotosListTableComponent />
        </Box>
    );
}
