import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { HolderLink } from '../../holders/elements/HolderLink';
import { useChargeLocation, WithChargeLocation } from '../../wrappers/WithChargeLocation';
import {ActionEventListPageService, Options} from "../../event-log/components/table/ListPageService";
import React, { PropsWithChildren } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import {MorphPhotoService} from "../services/MorphPhotoService";
import {useOperatorId} from "../../../hooks/useOperatorId";

function MorphPhotoItem({photo}: any) {
    const navigate = useNavigate();
    const operatorId = useOperatorId()
    const toggleStatus = MorphPhotoService.useStore(store => store.toggleApprovedStatus);

    return (
        <TableRow
            key={photo._id}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell onClick={() => navigate(`/${operatorId}/locations/${photo.entityId}`)}>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    <Typography fontSize={'inherit'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        { photo.entityName }
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell onClick={() => navigate(`/${operatorId}/customers/${photo.account}`)}>
                {photo.accountName }
            </TableCell>
            <TableCell>
                <img onClick={() => window.open(photo.photoUrl, "_blank")} height={145} width={200} src={photo.photoUrl}/>
            </TableCell>
            <TableCell>
                {photo.created }
            </TableCell>
            <TableCell>
                <Button onClick={() => toggleStatus(photo._id)} variant="contained" color={photo.isApproved ? 'secondary' : 'warning'}>
                    { photo.isApproved ? 'Запретить' : 'Разрешить' }
                </Button>
            </TableCell>
            {/*<TableCell>*/}
            {/*    <OperatorLink id={location.operator} />*/}
            {/*</TableCell>*/}
            {/*<TableCell>*/}
            {/*    <HolderLink id={location.holder} />*/}
            {/*</TableCell>*/}
        </TableRow>
    );
}

interface ChargeLocationTableProps {
    locationIds: string[];
}
function MorphPhotosTable({ locationIds }: ChargeLocationTableProps) {
    const photos = MorphPhotoService.useStore(store => store.photos);

    // const useLocations = useChargeLocation()

    // console.log(useLocations);

    console.log(photos);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Адрес</TableCell>
                        <TableCell>Клиент</TableCell>
                        <TableCell>Фотография</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        photos.map((photo, key) => (
                            <MorphPhotoItem photo={photo} key={key}></MorphPhotoItem>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface Filter {
    operator?: string;
    holder?: string;
}

interface Props {
    filter?: Filter;
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = MorphPhotoService.useStore(store => store.loadNext);
    const reset = MorphPhotoService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface ActionEventListTableProps {
    filter?: Partial<Filter>;
    options?: Partial<Options>;
}

export default function MorphPhotosListTableComponent({ filter = {}, options = {} }: ActionEventListTableProps) {
    // const filteredLocationIds = useFilteredLocationIds(filter);

    return <MorphPhotoService.Provider
        createStore={() => MorphPhotoService.createStore({}, {})}
    >
        <LoadWrapper>
            <MorphPhotosTable locationIds={[]}/>;
        </LoadWrapper>
    </MorphPhotoService.Provider>
}
