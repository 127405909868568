import {EventLog, EventLogType} from '@electrifly/central-client-api';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {StoreApi} from 'zustand';
import createContext from 'zustand/context';
import {API} from "../../../core/api-client";
import {createWithImmer} from "../../../misc/CreateWithImmer";
import {ColumnKey} from "../../event-log/components/table/TableColumns";

export type Filter = {
    limit: number;
    search?: string;
    types: EventLogType[];
    transactionHumanId?: number;
    chargePoint?: string;
    customer?: string;
    rangeStart?: number;
    rangeEnd?: number;
};

export type FilterUIType = 'eventlog' | 'chargepoint' | 'transaction' | 'customer' | 'date';

export type Options = Record<ColumnKey, boolean>;

type Data = {
    reviews: any[];
    loading: boolean;
    canLoadMore: boolean;

    filter: Filter;
    filterVisability: FilterUIType[];
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    toggleApprovedStatus: (reviewId: string) => Promise<void>
};

type Service = Data & Actions;

const DEFAULT_FILTER: Filter = {
    search: '',
    limit: 100,
    types: [],
};

function createDefaultData(): Data {
    return {
        reviews: [],
        loading: false,
        canLoadMore: false,

        filter: DEFAULT_FILTER,
        filterVisability: [],
    };
}

function transactionPassFilter(eventLog: EventLog, filter: Filter): boolean {
    let passed = true;
    if (filter.search) {
        // passed = passed && new BigNumber(filter.search).isEqualTo(eventLog.transactionId);
    }

    return passed;
}

const createStore = (filter?: Partial<Filter> | undefined, options?: Partial<Options> | undefined) => {
    const initialData = {
        ...createDefaultData(),
        filter: {...createDefaultData().filter, ...filter},
    };

    return createWithImmer<Service>((set, get) => {
        // WebsocketClient.events.ACTION_EVENT.on(actionEvent => {
        //     const { events, filter } = get();
        //     const passed = transactionPassFilter(actionEvent, filter);
        //     if (!passed) {
        //         set(draft => {
        //             const index = draft.events.findIndex(item => item._id === actionEvent._id);
        //             if (index !== -1) {
        //                 draft.events.splice(index, 1);
        //             }
        //         });
        //
        //         return;
        //     }
        // });

        function resetData() {
            set({reviews: []});
        }

        async function toggleApprovedStatus(reviewId: string) {
            const [error, res] = await API.toggleReviewApproveStatus(reviewId)

            if (error) {
                console.log(error);
                return
            }

            const newPhotos = get().reviews.map(e => {
                if (e._id == res.data._id) {
                    return {
                        ...e,
                        isApproved: res.data.isApproved,
                    }
                }
                return {
                    ...e,
                }
            })

            set({reviews: newPhotos})

            console.log(error);
            console.log(res?.data);
        }

        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({loading: true});

            const {filter, reviews} = get();

            const skip = reviews.length;
            const [error, res] = await API.reviewsList({
                skip,
                limit: filter.limit,
                types: filter.types,
                transactionHumanId: filter.transactionHumanId,
                chargePoints: filter.chargePoint ? [filter.chargePoint] : undefined,
                customers: filter.customer ? [filter.customer] : undefined,
                rangeStart: filter.rangeStart,
                // Range End day is included
                rangeEnd: filter.rangeEnd && DateTime.fromMillis(filter.rangeEnd).plus({days: 1}).toMillis(),
            });

            console.log(res?.data);

            if (error) {
                console.error(error);
                set({loading: false});
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                canLoadMore: canLoadMore,
                reviews: [...get().reviews, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,
            reset: () => set({...initialData}),
            loadNext: loadNext,
            toggleApprovedStatus: toggleApprovedStatus,
        };
    });
};

const {Provider, useStore} = createContext<StoreApi<Service>>();

export const ReviewService = {
    Provider,
    createStore,
    useStore,
};
