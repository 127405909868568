import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {Options} from "../../event-log/components/table/ListPageService";
import React, { PropsWithChildren } from 'react';
import {useOperatorId} from "../../../hooks/useOperatorId";
import {ReviewService} from "./ReviewService";
import { useEffectOnce } from 'usehooks-ts';

function ReviewTableItem({review}: any) {
    const navigate = useNavigate();
    const operatorId = useOperatorId()
    const toggleStatus = ReviewService.useStore(store => store.toggleApprovedStatus);

    return (
        <TableRow key={review._id} hover={true} sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell>
                <a href="#" onClick={() => navigate(`/${operatorId}/locations/${review.location}`)}>
                    <Stack direction={'row'} alignItems="center" spacing={1}>
                        <Typography fontSize={'inherit'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            { review.locationName }
                        </Typography>
                    </Stack>
                </a>
            </TableCell>
            <TableCell>
                <a href="#" onClick={() => navigate(`/${operatorId}/customers/${review.account}`)}>
                    {review.accountName }
                </a>
            </TableCell>
            <TableCell>
                { review.transaction ?? 'Отсутствует, обычный отзыв' }
            </TableCell>
            <TableCell>
                { review.stars }
            </TableCell>
            <TableCell>
                {review.message }
            </TableCell>
            <TableCell>
                {review.created }
            </TableCell>
            <TableCell>
                <Button onClick={() => toggleStatus(review._id)} variant="contained" color={review.isApproved ? 'warning' : 'success'}>
                    { review.isApproved ? 'Запретить' : 'Разрешить' }
                </Button>
            </TableCell>
            {/*<TableCell>*/}
            {/*    <OperatorLink id={location.operator} />*/}
            {/*</TableCell>*/}
            {/*<TableCell>*/}
            {/*    <HolderLink id={location.holder} />*/}
            {/*</TableCell>*/}
        </TableRow>
    );
}

interface ChargeLocationTableProps {
    locationIds: string[];
}
function MorphPhotosTable({ locationIds }: ChargeLocationTableProps) {
    const reviews = ReviewService.useStore(store => store.reviews);

    // const useLocations = useChargeLocation()

    // console.log(useLocations);

    // console.log(photos);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Адрес</TableCell>
                        <TableCell>Клиент</TableCell>
                        <TableCell>Транзакция</TableCell>
                        <TableCell>Оценка</TableCell>
                        <TableCell>Сообщение</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        reviews.map((review, key) => (
                            <ReviewTableItem review={review} key={key}></ReviewTableItem>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface Filter {
    operator?: string;
    holder?: string;
}

interface Props {
    filter?: Filter;
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = ReviewService.useStore(store => store.loadNext);
    const reset = ReviewService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface ActionEventListTableProps {
    filter?: Partial<Filter>;
    options?: Partial<Options>;
}

export default function ReviewsListTableComponent({ filter = {}, options = {} }: ActionEventListTableProps) {
    // const filteredLocationIds = useFilteredLocationIds(filter);

    return <ReviewService.Provider createStore={() => ReviewService.createStore({}, {})}>
        <LoadWrapper>
            <MorphPhotosTable locationIds={[]}/>;
        </LoadWrapper>
    </ReviewService.Provider>
}
