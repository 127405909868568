import { Box, Stack } from '@mui/material';

import { Title } from '../../elements/Title';
import { useParams } from 'react-router-dom';
import ReviewsListTableComponent from "./components/ReviewsListTableComponent";

export function PageHeader() {
    const { operatorId } = useParams();

    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Отзывы</Title>
        </Stack>
    );
}

export default function ReviewsListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader/>
            <ReviewsListTableComponent />
        </Box>
    );
}
